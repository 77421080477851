import LoginBox from './LoginBox';
import VerificarConta from "./VerificarConta"
import style from "./index.module.css";
import bible from "../Imgs/bible.png";
import girl1 from "../Imgs/girlPraying.png";
import jesus2 from "../Imgs/Jesus2.png";
import girl2 from "../Imgs/girlPraying2.png";
import boy from "../Imgs/boyPraying1.png";
import jesus from "../Imgs/Jesus.png";
import apiUrl from "../global";
import { useState } from 'react';

export default function Index({setListenerWorking, user}){
    const [teste,setTeste] = useState("Testar conexão backend");
    return(
    <div className={style.loginBox}>
        <div className={style.espacoEsquerdo}>
            <div className={style.introduction}>
                <h1>Hevelim</h1>
                <p>
                Conecte-se com a comunidade cristã, compartilhe sua fé e encontre inspiração diária em um espaço onde a liberdade de expressão é valorizada. Nossa rede social cristã promove crescimento espiritual, apoio e amizades genuínas.
                ~VERSÃO BETA~
                </p>
                <div className={style.containerAllImgs}>
                <div className={style.imgContainer}><img src={jesus2} alt="" /></div>
                <div className={style.imgContainer}><img src={girl1} alt="" /></div>
                <div className={style.imgContainer}><img src={boy} alt="" /></div>
                <div className={style.imgContainer}><img src={girl2} alt="" /></div>
                <div className={style.imgContainer}><img src={jesus} alt="" /></div>
                <div className={style.imgContainer}><img src={bible} alt="" /></div>

                </div>
            </div>
        </div>
        <div className={style.espacoDireito}>
            {user && !user.isUserVerified?
            <div className={style.loginBoxContainer}><VerificarConta user={user}/></div>
            :
            <div className={style.loginBoxContainer}><LoginBox setListenerWorking={setListenerWorking}/></div>
            }
        </div>
    </div>
    )
}