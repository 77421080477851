import style from "./style.module.css";
import { deleteComment, obterToken } from "../../../global";
import { MdArrowBack } from 'react-icons/md';
import { MdClose } from 'react-icons/md'; 
import apiUrl from "../../../global";
import { useEffect, useState } from "react";

/*
    stagio 1 - Selecionar opção de denuncia(spam, nudez etc)
    stagio 2 - Confirmação de denuncia
    stagio 3 - Denunciado com sucesso
    stagio 4 - Você já denunciou esse comentario (contudo o código tenta tirar o comentario da visualizacao)
    stagio 5 - Erro ao denunciar
*/
function DenunciarModal({setOption, handler, setHandler}){ //PS: "handler" tem tudo, id, comentario, handler, etc
    const [tiposDenuncia, setTiposDenuncia] = useState([]);
    const [stagio, setStagio] = useState("stagio1"); 
    const [erro,setErro] = useState('');

    function fecharModal(){ //Fechar modal
        setHandler(prevState=>({
            ...prevState,
            ativo:false
        }))
    }
    const [opcaoSelecionada, setOpcaoSelecionada] = useState(); //Id opcao selecionada
    useEffect(()=>{
        async function getTipoDenuncia(){
            const token = await obterToken();
            try { 
                const token = await obterToken();
                
                const response = await fetch(`${apiUrl}/getTipoDenunciaComent`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                });
            
                if (!response.ok) {
                  const errorData = await response.json();
                  throw new Error(errorData.message || 'Erro desconhecido');
                }
            
                const data = await response.json();
                setTiposDenuncia(data);
                
              } catch (error) {
                throw new Error(error.message || 'Erro desconhecido');
              }
        }
        getTipoDenuncia();
    },[])

    function handleSelecionarOpcao(opcao){
        setOpcaoSelecionada(opcao); //Id opcao selecionada
        setStagio("stagio2"); 
    }

    
    async function handleDenunciar(){
        //const idPostagem = postagemSelecionadaDados.id;
        const idComentario = handler.comentario.id;
        const opcao = opcaoSelecionada;
        const comentario = handler.comentario.comentario;
        const postagemId = handler.comentario.postagemId;
        try {
            const token = await obterToken();
            
            const response = await fetch(`${apiUrl}/denunciaComentario`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    idComentario,
                    opcao,
                    comentario,
                    postagemId
                }),
            });

            const result = await response.json();
            if(result.tipo=="vcjadenunciou"){
                setStagio("stagio4");
            }else if(result.tipo=="sucessodenuncia"){ //Denunciado com sucesso
                setStagio("stagio3");
            }else if(result.tipo="erro"){
                setStagio("stagio5");
                setErro(result.message);
            }
         
        
        } catch (error) {
            console.error('Erro ao criar denuncia:', error);
            setStagio("stagio5"); //Exibe erro
        }
    }
    
    return (
            <div className={style.containerDenuncia}>
                {stagio=="stagio1"?
                    <div>
                        <div className={style.reasonContainer}>
                        <MdArrowBack className={style.icon} size={24} onClick={()=>setOption("inicio")}/>
                        <p className={style.title}>Selecione o motivo</p>
                        <MdClose size={24} className={style.icon} onClick={()=>fecharModal()}/>
                        </div>

                        <ul className={style.ulDenuncia}>
                            {tiposDenuncia && tiposDenuncia.data && tiposDenuncia.data.length > 0 ? (
                            tiposDenuncia.data.map((tipo, index) => (
                                <li key={index} onClick={()=>handleSelecionarOpcao(tipo.id)}> {tipo.nome}</li>
                            ))
                            ) : (
                            <li>Erro ao exibir tipos de denuncia</li> // Exibe uma mensagem de fallback
                            )}
                        </ul>

                    </div>
                : stagio=="stagio2"? //Confirmacao de denuncia
                    <div>
                        <p className={style.titulo}>Você tem certeza que quer denunciar?</p>
                        <p className={style.corpoDenuncia}>
                        Sua denúncia será cuidadosamente revisada pelos nossos moderadores. 
                        Dependendo da gravidade da situação, a postagem pode ser removida ou o usuário responsável pode enfrentar medidas como a perda da conta.
                        Agradecemos sinceramente por ajudar a manter nossa comunidade segura e acolhedora.
                        </p>
                        <div className={style.groupButton}>
                            <button className={style.botaoDenunciar} onClick={()=>handleDenunciar()}>Denunciar</button>
                            <button className={style.botaoCancelar} onClick={()=>fecharModal()}>Cancelar</button>
                        </div>
                    </div>
                :stagio=="stagio3"? //Denunciado com sucesso
                    <div>
                        <p className={style.titulo}>Denuncia enviada com sucesso!</p>
                        <p className={style.corpoDenuncia}>
                        Agradecemos por sua denúncia! Sua contribuição é importante para manter nossa comunidade segura e acolhedora.
                        Nossa equipe de moderadores irá revisar o conteúdo denunciado e tomar as medidas necessárias conforme nossas políticas.
                        Obrigado por nos ajudar a melhorar a plataforma, e fique com Deus.
                        </p>
                        <div className={style.groupButton}>
                            <button className={style.botaoCancelar} onClick={()=>fecharModal()}>Ok</button>
                        </div>
                    </div>
                :stagio=="stagio4"? //Vc já denunciou
                <div>
                    <p className={style.titulo}>Você já denunciou essa postagem!</p>
                    <p className={style.corpoDenuncia}>
                    Não se preocupe, sua denúncia já foi registrada e será revisada com atenção pelos nossos moderadores.
                    Agradecemos por ajudar a manter nossa comunidade segura. 
                    Caso tenha denunciado novamente por ter selecionado o tópico errado, nós arrumaremos para você!
                    Fique com Deus.
                    </p>
                    <div className={style.groupButton}>
                        <button className={style.botaoCancelar} onClick={()=>fecharModal()}>Ok</button>
                    </div>
                </div>    
                : //Erros
                <div>
                    <p className={style.titulo}>Poxa, deu erro =/</p>
                    <p className={style.corpoDenuncia}>
                    Algo deu errado ao tentar processar sua denúncia.
                    Pedimos mil desculpas pelo inconveniente. Sei o quanto é chato dar erro logo na denúncia.
                    Por favor, tente novamente mais tarde.
                    Se o problema persistir, entre em contato com um moderador para obter assistência.
                    Agradecemos sua paciência e compreensão.
                    Saiba disto: estamos sempre averiguando erros, e não demoraremos a concertá-lo.
                    Erro: {erro}
                    Fique com Deus.
                    </p>
                    <div className={style.groupButton}>
                        <button className={style.botaoCancelar} onClick={()=>fecharModal()}>Ok</button>
                    </div>
                </div>         
                }
            </div>
    );
}


export default function Index({handler, setHandler}){ //setHandler é o handler q fecha o modal todo
    const [option, setOption] = useState("inicio");
    /*Handler possui:
        ativo: false //Guarda estado de abrir ou fechar modal
        userId:user.uid    //Guarda usuario logado
        comentario //Guarda o comentario, como quem comentou (uid), oq comentou,postagemId etc
        setComentarios // todos os comentarios
        setPostagem: postagemSelecionadaDados //Para atualizar a quantidadeDeComentarios da postagem
    */
    function onClickFadeBackground(){
        setHandler(prevState=>({
            ...prevState,
            ativo:false
        }))
    }

    async function deletarComentario(){
        try{
            const result = await deleteComment(handler.comentario.id);
            if(result){ //Fecha modal
                //Tirar o comentario da lista de comentarios:
                handler.setComentarios(prevComentarios =>
                    prevComentarios.filter(comentario => comentario.id !== handler.comentario.id)
                );

                //Decrementar a quantidade de comentarios no front-end:
                handler.setPostagem(prevState => ({
                    ...prevState,
                    quantidadeDeComentarios: prevState.quantidadeDeComentarios - 1
                }));

                //fechar modal:
                setHandler(prevState=>({
                    ...prevState,
                    ativo:false
                }))
            }else{
                console.log(result);
            }
        }catch(erro){
            console.log("Erro: ",erro);
        }
       
    }

    return(
        <>
        <div className={style.fadeBackground} onClick={onClickFadeBackground}></div>
        <div className={style.modalContainer}>
                {option=="inicio"?
                    handler.comentario.userUid===handler.userId? //DOno do comentario
                    <ul className={style.ulOpcoes}>
                         <li className={style.redOption} onClick={deletarComentario}>
                            <p>Deletar comentário</p>
                         </li>
                    </ul>
                    :
                    handler.comentario.userUid!==handler.userId&& //N é dono do comentario
                    <ul className={style.ulOpcoes}>
                         <li className={style.redOption}  onClick={()=>setOption("denuncia")}>
                            <p>Denunciar</p>
                        </li>
                    </ul>
                :option=="denuncia"&&
                <DenunciarModal handler={handler} setOption={setOption} setHandler={setHandler}/>
                }
        </div>
        </>
    )
}