import style from "./index.module.css";
import { capitalizeFirstLetter } from "../../global";
import { useNavigate } from 'react-router-dom';

export default function Index({postagem}) {
    const { nome, sobrenome, username, profile_image, postCount, seguidorCount, seguindoCount } = postagem.postador;
    
    const navigate = useNavigate();
    // Função para lidar com a navegação
    const handleNavigate = () => {
        navigate(`/${username}`);
    };

    return (
        <div className={style.container}>
            <div className={style.containerUpper}>
                <img className={style.userImage} onClick={handleNavigate} src={profile_image} alt="User" />
                <div className={style.nameAndusername} onClick={handleNavigate}>
                    <p className={style.name}>{capitalizeFirstLetter(nome)} {capitalizeFirstLetter(sobrenome)}</p>
                    <p className={style.userName}>@{username}</p>
                </div>
            </div>
            
            <div className={style.contaimerMiddle}>
                <div className={style.atributesContainer}>
                    <span>{postCount}</span>
                    <span>postagens</span>
                </div>
                <div className={style.atributesContainer}>
                    <span>{seguidorCount}</span>
                    <span>seguidores</span>
                </div>
                <div className={style.atributesContainer}>
                    <span>{seguindoCount}</span>
                    <span>seguindo</span>
                </div>
            </div>

        </div>
    );
}
