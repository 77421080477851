import styleTab from "./index.module.css";
import { useState, useEffect } from "react";
import ModalPostagemUnica from "../../Home/ModalPostagemUnica";
import React from 'react';
import loadingIcon from "../../Imgs/loadingIcon.gif"
import { fetchAndSetPostagens } from "../../global";


export const TabComponent = ({atingiuFimPostagens, loading,dataDonoPerfil,setAtingiuFimPostagens,setLoadMore,user,gerarMsgErro,loadMore, setAtingiuFimShorts,atingiuFimShorts, activeTab, setActiveTab}) => {
    /*
    Functiomanento:
        Parâmetros:
          -> atingiuFimPostagensé true quando não tem mais postagens pra load
          -> loadMore é true quando chega no fim do scroll, pega postagens no home e desativa de novo.
          -> loading usado quando as postagens estão em load, bom pra colocar gif de loading
    */
    /*Variaveis postagem (imgs)*/
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [listaPostagens,setListaPostagens] = useState(null);
    /*Variaveis Shorts (videos)*/
    const [limitShorts,setLimitShorts] = useState(10);
    const [offsetShorts,setOffsetShorts] = useState(0);
    const [listaShorts,setListaShorts] = useState(null);
    /*Variaveis ambas*/
    const userId = user.uid;
    const [showModalPostagemUnicaHandler, setShowModalPostagemUnicaHandler] = useState(false);
    const [postagemSelecionadaDados, setPostagemSelecionadaDados] = useState({});

    
    async function getUserPosts(){
        try{
            const postagensNovas = await fetchAndSetPostagens(dataDonoPerfil.uid,"especificuser",offset,limit,userId,'posts');
            if(listaPostagens==null){setListaPostagens(postagensNovas);}
            else 
            {setListaPostagens(prevPostagens => [...prevPostagens, ...postagensNovas])};

            if (postagensNovas.length < limit) {
                setAtingiuFimPostagens(true); // Define que atingiu o fim se menos postagens forem retornadas
            }
        }catch(err){
            gerarMsgErro(1002);
        }finally{
            setOffset(prevOffset => prevOffset + limit);
            setLoadMore(false);
        }
        
    }
    async function getUserShorts(){
        try{
            const novosShorts = await fetchAndSetPostagens(dataDonoPerfil.uid,"especificuser",offsetShorts,limitShorts,userId,'shorts');
            if(listaShorts==null){setListaShorts(novosShorts);}
            else 
            {setListaShorts(prevPostagens => [...prevPostagens, ...novosShorts])};

            if (novosShorts.length < limitShorts) { //Chegou menos postagens doq deveria, significa q n tem mais
                setAtingiuFimShorts(true); // Define que atingiu o fim se menos postagens forem retornadas
            }
        }catch(err){
            gerarMsgErro(1002);
        }finally{
            setOffsetShorts(prevOffset => prevOffset + limitShorts);
            setLoadMore(false);
        }
        
    }

    useEffect(()=>{
        setLoadMore(true);
    },[activeTab])
    
    useEffect(()=>{ //Pegar postages do dono do perfil. 
        if(loadMore===true && dataDonoPerfil && activeTab=='posts' && !atingiuFimPostagens){
            getUserPosts();
        }

        if(loadMore===true && dataDonoPerfil && activeTab=='shorts' && !atingiuFimShorts){
            getUserShorts();
        }
    },[loadMore])

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    return (
        <div>
            {showModalPostagemUnicaHandler && (
                <ModalPostagemUnica
                    setPostagemSelecionadaDados={setPostagemSelecionadaDados}
                    postagemSelecionadaDados={postagemSelecionadaDados}
                    setShowModalPostagemUnicaHandler={setShowModalPostagemUnicaHandler}
                    setListaPostagens={setListaPostagens}
                    user={user} //Usuario logado
                    midia={true}
                />
            )}
            <div className={styleTab.buttonContainer}>
                <button
                    className={`${styleTab.tabButton} ${activeTab === 'posts' ? styleTab.active : ''}`}
                    onClick={() => handleTabClick('posts')}
                >
                    Imagens
                </button>
                <button
                    className={`${styleTab.tabButton} ${activeTab === 'shorts' ? styleTab.active : ''}`}
                    onClick={() => handleTabClick('shorts')}
                >
                    Shorts
                </button>
                <button
                    className={`${styleTab.tabButton} ${activeTab === 'saved' ? styleTab.active : ''}`}
                    onClick={() => handleTabClick('saved')}
                >
                    Salvos
                </button>
            </div>

            <div className={styleTab.content}>
                <div className={`${styleTab.contentTab} ${activeTab === 'posts' ? styleTab.active : ''}`} id="posts">
                    <div className={styleTab.containerFlex}>
                        {/* Conteúdo de Publicações */}                        
                        
                        {listaPostagens !== undefined && listaPostagens !== null &&
                        listaPostagens.length > 0 &&
                        listaPostagens.map((postagem) => (
                            postagem.imagens.length > 0 && (
                                <span key={postagem.id} className={styleTab.spanContainer}>
                                    <img
                                        onClick={() => {setPostagemSelecionadaDados(postagem);setShowModalPostagemUnicaHandler(true);}}
                                        className={styleTab.postagemImg}
                                        src={postagem.imagens[0].caminhoImagem}
                                        alt="Imagem da postagem"
                                    />
                                </span>
                            )
                        ))
                        }

                        {!loading && <div className={styleTab.divGift}><img src={loadingIcon} className={styleTab.gifLoading}/></div>}

                        {atingiuFimPostagens && (
                        Object.keys(listaPostagens).length>0? <div className={styleTab.endMessageDiv}><p className={styleTab.fimPostagens}>Não há mais nada aqui.</p></div>
                        : 
                        //Não tem nenhuma postagem pra ver
                        <div className={styleTab.endMessageDiv}><p className={styleTab.fimPostagens}>Nenhuma imagem compartilhada.</p></div>
                        )}

                    </div>
                </div>
                <div className={`${styleTab.contentTab} ${activeTab === 'shorts' ? styleTab.active : ''}`} id="shorts">
                    {/* Conteúdo de Vídeos */}
                        <div className={styleTab.containerFlex}>
                            {listaShorts !== undefined && listaShorts !== null &&
                            listaShorts.length > 0 &&
                            listaShorts.map((short) => (
                                short.video.caminhoImagem.length > 0 && (
                                    <span key={short.id} className={styleTab.spanContainer}>
                                        <video
                                            onClick={(e) => {e.preventDefault(); setPostagemSelecionadaDados(short);setShowModalPostagemUnicaHandler(true);}} 
                                            onPlay={(e) => e.preventDefault()}
                                            className={styleTab.postagemVideo}
                                            src={short.video.caminhoImagem}
                                            alt="Vídeo"
                                            preload="metadata"
                                            //Pra quando tiver thumbnail:
                                            //preload="none"
                                            //poster={short.video.thumbnail}
                                        >
                                        </video>
                                    </span>
                                )
                            ))
                            }

                            {!loading && <div className={styleTab.divGift}><img src={loadingIcon} className={styleTab.gifLoading}/></div>}

                            {atingiuFimShorts && (
                            //Visualizou todos os vídeos
                            Object.keys(listaShorts).length>0? <div className={styleTab.endMessageDiv}><p className={styleTab.fimPostagens}>Não há mais nada aqui.</p></div>
                            : 
                            //Não tem nenhum vídeo para ver
                            <div className={styleTab.endMessageDiv}><p className={styleTab.fimPostagens}>Nenhum vídeo compartilhado.</p></div>
                            )}
                        </div>
                </div>
                <div className={`${styleTab.contentTab} ${activeTab === 'saved' ? styleTab.active : ''}`} id="saved">
                    {/* Conteúdo de Salvos */}Nada aqui por enquanto.
                </div>
            </div>
        </div>
    );
};
