import { useState, useEffect,useRef  } from 'react';
import style from "./index.module.css";
import { Navigate, useNavigate } from 'react-router-dom';
import { handleLogout } from '../../../global';
export default function MenuTopo({setModalCriarPostagem, setModalCriarShorts, user}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };




  /*Combo para desativar menú ao clicar fora dele */
  const menuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false); // Fecha o menu ao clicar ou arrastar fora
    }
  };
  useEffect(() => {
    // Adiciona o event listener para cliques no documento (onMouseDown)
    document.addEventListener('mousedown', handleClickOutside);

    // Remove o listener ao desmontar o componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
/*Fim combo desativar menú ao clicar fora dele */



  return (
    <div className={style.container} ref={menuRef}>
      <div className={style.hamburger} onClick={toggleMenu}>
        <div className={style.line}></div>
        <div className={style.line}></div>
        <div className={style.line}></div>
      </div>
      {isOpen && (
        <div className={style.dropdownMenu}>
          <ul>
            <li onClick={()=>{setModalCriarShorts(true);setIsOpen(false);}}>Criar shorts</li>
            <li onClick={()=>{setModalCriarPostagem(true);setIsOpen(false);}}>Criar postagem</li>
            {
              (user.claims=="admin" || user.claims=="moderador")&&
              <li onClick={()=>{navigate('/moderador')}}>Moderador</li>
            }
            <li onClick={()=>handleLogout(navigate)}>Sair</li>
            {/* Adicione mais itens aqui se precisar */}
          </ul>
        </div>
      )}
    </div>
  );
}
