import apiUrl from '../../../global';
import style from './style.module.css'; // Arquivo CSS para estilos
import {obterToken, calcularTempoDecorrido} from '../../../global';
import { useEffect,useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { RiSafariFill } from 'react-icons/ri';

function ConfirmarOpcao({opcao, setOpcaoSelecionada, comentarioSelecionado, setComentarios}){
  const { idDenunciaComent, idComentario } = comentarioSelecionado;

  async function julgarPostagem(){
      try {
        const token = await obterToken();

        const response = await fetch(`${apiUrl}/julgarComentario`, {
            method: 'POST', // Mude para POST
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              idDenunciaComent,opcao,idComentario
            })
        });

        console.log(response);
      
      } catch (err) {
          console.log(err);
      }
  }
  return(
    <>
      <div className={style.opcaoContainer}>
        <span className={style.containerTitle}>
          <span/>
          <p className={style.opcaoTitle}>Confirmar opção</p>
          <FaTimes className={style.closeIcon} onClick={()=>setOpcaoSelecionada("")}/> 
        </span>
        <p>
          Deseja realmente <span className={style.bold}>{opcao}</span> usuario?
        </p>
        {
          opcao=="liberado"?
          <button className={style.btnLiberar} onClick={julgarPostagem}>OK</button>
          :opcao=="warning"?
          <button className={style.btnWarning} onClick={julgarPostagem}>OK</button>
          :opcao=="excluirComent"?
          <button className={style.btnDeletar} onClick={julgarPostagem}>OK</button>
          :opcao=="banirUser"&&
          <button className={style.btnBanir} onClick={julgarPostagem}>OK</button>
        }
      </div>
      <div className={style.fadeBackground} onClick={()=>setOpcaoSelecionada("")}/>
    </>
  )
}


export default function TodosComentarios() {
    const [comentarios, setComentarios] = useState([]);
    const [error, setError] = useState(null);
    const [loadingComments, setLoadingComments] = useState(true); //Armazena se está em load ou não os comentários
    const [limitLoad, setLimitLoad] = useState(10); //Quantos comentários carregam por vez
    const [offsetLoad, setOffsetLoad] = useState(0); //Vai carregar os comentários a partir desse indice
    const [exibirShowMore, setExibirShowMore] = useState(false); //N exiba o showMore de início
    const [opcaoSelecionada, setOpcaoSelecionada] = useState(""); //Guardar opcao do julgamento
    const [comentarioSelecionado, setComentarioSelecionado] = useState([]);
    function ShowMore(){
      function onClickShowMore(){
        setOffsetLoad(offsetLoad+10);
      }
      return(
        <span>
        {exibirShowMore ? (
          <span onClick={onClickShowMore} className={style.exibirMais}>Exibir mais</span>
        ) : (
        <span>&nbsp;</span> //Espaço q o Exibir mais ocupa, é bom ter pra melhorar experiência do usuário
        )}
      </span>
      )
    }
    function handleOpenPost(url){
        const newUrl = `?p=${url}`;
        window.history.pushState({}, '', newUrl);
        // Aqui você pode abrir o modal
    }

    useEffect(() => { // Pegar os comentários e dados do usuário q comentou
      setLoadingComments(true);
      const fetchComentarios = async () => {
        try {
            const token = await obterToken();
            // Buscar comentários do backend
            const response = await fetch(`${apiUrl}/comentarios`, {
                method: 'POST', // Mude para POST
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    limitLoad: limitLoad,
                    offsetLoad: offsetLoad,
                    moderador:true, //Obviamente eu verifico token se é mod, isso é pra qd o moderador tiver usando plataforma normalmente, ele n veja só comentarios q foi denunciado kk
                })
            });
    
            const comentarios = await response.json(); // Aguarde a resposta JSON
            console.log(comentarios);
            const numeroDeComentarios = comentarios.length; // Quantidade de comentários retornados
            setExibirShowMore(numeroDeComentarios === limitLoad); // Se chegou realmente os 10 comentários, pode ser que tenha mais
            
            if (offsetLoad === 0) {
                setComentarios(comentarios); // Primeiros 10 comentários
            } else {
                setComentarios(prevComentarios => [...prevComentarios, ...comentarios]); // Outros entram em congregação
            }
    
            setLoadingComments(false);
        } catch (err) {
            setError('Erro ao buscar comentários');
            setLoadingComments(false);
        }
      };
      fetchComentarios();
    },[offsetLoad]);
  
    return (<>
        <div className={style.comentario}>
        {opcaoSelecionada && <ConfirmarOpcao comentarioSelecionado={comentarioSelecionado} opcao={opcaoSelecionada} setOpcaoSelecionada={setOpcaoSelecionada} setComentarios={setComentarios}/>}
        {comentarios && comentarios.length!=0 && comentarios.map((comentario, index) => (
           <span key={comentario.idDenunciaComent}>
                <div className={style.comentarioUpper}> {/*Flex row*/}
                    <img className={style.imgComents} src={comentario.profile_image} alt="Avatar" />
                    <div className={style.nomeEComentario}>
                        <p className={style.nomeComentarista}>{comentario.nome} {comentario.sobrenome} </p>
                        <p className={style.usernameComentarista}>@{comentario.username}</p>
                        <span>{comentario.comentario}</span>
                        <div className={style.atributosComentario}>
                        <span>
                          {comentario.qtdCurtidas!=null?comentario.qtdCurtidas + " amei":<p className={style.deletado}>Comentário excluído</p>
                          } 
                        </span>
                        <p>{calcularTempoDecorrido(comentario.dataComentario)}</p>
                          {
                            comentario.url?
                            <button className={style.abrirPost} onClick={()=>handleOpenPost(comentario.url)}>Abrir post</button>
                            :
                            <p className={style.deletado}>Post excluído</p>
                          }
                        </div>
                    </div>
                </div>
                <span className={style.botoesJulgamento}>
                  <button className={style.btnLiberar} onClick={()=>{setComentarioSelecionado(comentario);setOpcaoSelecionada("liberado")}}>Liberar</button>
                  <button className={style.btnWarning} onClick={()=>{setComentarioSelecionado(comentario);setOpcaoSelecionada("warning")}}>Warning (ocultar do publico +/ou pouco dac)</button>
                  <button className={style.btnDeletar} onClick={()=>{setComentarioSelecionado(comentario);setOpcaoSelecionada("excluirComent")}}>Deletar +/ou dac</button>
                  <button className={style.btnBanir} onClick={()=>{setComentarioSelecionado(comentario);setOpcaoSelecionada("banirUser")}}>Banir usuário</button>
                </span>
            <hr className={style.hr}/>
          </span>
        ))}
      </div>{<ShowMore/>}
    </>);
}