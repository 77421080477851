import style from "./index.module.css";
import novo from "../../../Imgs/novo2.png";
import DescricaoComMais from "../../DescricaoComMais/DescricaoComMais";
import ShowUserLittleProfile from "../../ShowUserLittleProfile"
import { calcularTempoDecorrido, obterToken} from "../../../global";
import apiUrl from "../../../global";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useEffect, useState } from "react";

export default function NotificacoesModal({notificacoesData, setNotificacoesData, user,onClickNotificacoes, setCountNovasNotificacoes }) {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [atingiuFim, setAtingiuFim] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  //Obtendo notificacoes
  useEffect(() => {
    setLoadMore(true);
  }, []);

  useEffect(() => { //Obtendo novas notificacoes iniciais
    if (loadMore === true) {
      obterNotificacoes();
    }

    async function obterNotificacoes() {
      const token = await obterToken();
      try {
        
        const response = await fetch(`${apiUrl}/obterNotificacoes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            offset,
            limit,
          }),
        });


        if (!response.ok) {
          const errorMessage = `Erro: ${response.status} ${response.statusText}`;
          console.error(errorMessage);
          throw new Error(errorMessage);
        }
        const result = await response.json();
        if (response.status === 200) {
          if(result.notifications){
            if (result.notifications.length < limit) {
              setAtingiuFim(true);
            }
          }else{
            setAtingiuFim(true);
          }

          setOffset((prevState) => prevState + limit);
          if (result.notifications) {
            console.log(result);
            setNotificacoesData((prevPostagens) => [...prevPostagens, ...result.notifications]);
          }
          setCountNovasNotificacoes(result.countNovasNotificacoes);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoadMore(false);
        console.log('Pegou dados com sucesso*****');
      }
    }
  }, [loadMore]);

 
    function onClickThumbnail(url) {
      if (!url) return;
  
      const params = new URLSearchParams(window.location.search);
      params.set('p', `${url}`);
      const newUrl = window.location.pathname + '?' + params.toString();
      window.history.replaceState({}, '', newUrl);
    }


    function gerarThumbnail(notificacao){
      return(
          <>
          {notificacao.curtida_postagemId && (
            <div
              className={style.thumbnailContainer}
              onClick={() => onClickThumbnail(notificacao.curtida_postagem.url)}
            >
              <img
                src={notificacao.curtida_postagem.thumbnail}
                className={style.thumbnail}
              />
            </div>
          )}

          {notificacao.curtida_comentarioId && (
            <div
              className={style.thumbnailContainer}
              onClick={() => onClickThumbnail(notificacao.curtida_comentario.url)}
            >
              <img
                src={notificacao.curtida_comentario.thumbnail}
                className={style.thumbnail}
              />
            </div>
          )}

          {notificacao.comentarioId && (
            <div
              className={style.thumbnailContainer}
              onClick={() => onClickThumbnail(notificacao.comentario.url)}
            >
              <img
                src={notificacao.comentario.thumbnail}
                className={style.thumbnail}
              />
            </div>
          )}



          {notificacao.denuncia.postagemIdDenuncia && (
            <div
              className={style.thumbnailContainer}
              onClick={() => onClickThumbnail(notificacao.denuncia.url)}
            >
              <img
                src={notificacao.denuncia.thumbnail}
                className={style.thumbnail}
              />
            </div>
          )}


        </>
      )
    }

    function handleCorpoDenuncia(notificacao){
      const status = notificacao.denuncia.statusDenuncia;
      let mensagem;

      switch (status) {
        case 'liberado':
          mensagem = 'Obrigado por contribuir com nossa comunidade ao realizar a denúncia. Após uma análise cuidadosa, verificamos que o conteúdo não se enquadra em uma violação das nossas diretrizes no momento. Agradecemos seu envolvimento e estamos sempre atentos para manter a plataforma segura para todos.';
          break;
        case 'warning':
          mensagem = 'Sua denúncia foi aprovada e a postagem foi retirada do público, contudo ainda é possível vê-la em seu perfil pessoal.';
          break;
        case 'rejeitada':
          mensagem = 'Sua denúncia foi rejeitada, sem ações adicionais.';
          break;
        default:
          mensagem = 'Status da denúncia desconhecido.';
      }
      
      return(
        <span className={style.mensagemDenuncia}>
        <DescricaoComMais descricao={mensagem} limite="80" />
        </span>
      )
    }


    function comentarioIdShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const notificacaoNova = user.ultimaNotificacao < notificacao.data;
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.comentario.thumbnail;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              {actorUser} comentou no seu post - {tempo}
              <span className={style.iconNotific}> 
                {notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(notificacao.comentario.url)}>
              <img src={thumbnail} className={style.thumbnail}/>
            </div>
          </div>
        
          <div className={style.bottom}>
              <div className={style.descricaoPostagem}>
                <DescricaoComMais descricao={notificacao.comentario.comentario} limite="80" />
              </div>
          </div>
        </div>
      )
    }

    function curtidapostagemShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const notificacaoNova = user.ultimaNotificacao < notificacao.data;
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.curtida_postagem.thumbnail;
      const url = notificacao.curtida_postagem.url;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              {actorUser} curtiu seu post - {tempo}
              <span className={style.iconNotific}> 
                {notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
              <img src={thumbnail} className={style.thumbnail}/>
            </div>
          </div>
        
        </div>
      )
    }

    function curtidaComentarioShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const notificacaoNova = user.ultimaNotificacao < notificacao.data;
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.curtida_comentario.thumbnail;
      const url = notificacao.curtida_comentario.url;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              {actorUser} curtiu seu comentario - {tempo}
              <span className={style.iconNotific}> 
                {notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
              <img src={thumbnail} className={style.thumbnail}/>
            </div>
          </div>
        </div>
      )
    }

    function denunciaShow(notificacao){ //Alguem comentou em uma postagem sua
      const tempo = calcularTempoDecorrido(notificacao.data);
      const notificacaoNova = user.ultimaNotificacao < notificacao.data;
      const actorUser = notificacao.actorUser.nome + " ";
      const thumbnail = notificacao.denuncia.thumbnail;
      const url = notificacao.denuncia.url;
      return(
        <div className={style.flexContainer}>
          <div className={style.tituloNot}>
            <p className={style.nomeDataNot}> 
              Resultado da denuncia - {tempo}
              <span className={style.iconNotific}> 
                {notificacaoNova && <img src={novo} className={style.novo}/>}
              </span>
            </p>
          </div>
            
          <div className={style.corpoNot}> {/*Flex row*/}
              <ShowUserLittleProfile user={notificacao.actorUser} />
              <div className={style.thumbnailContainer} onClick={() => onClickThumbnail(url)}>
                <img src={thumbnail} className={style.thumbnail}/>
              </div>
          </div>
        
          <div className={style.bottom}>
              <div className={style.descricaoPostagem}>
                {handleCorpoDenuncia(notificacao)}
              </div>
          </div>
        </div>
      )
    }

    return (
      <div className={style.searchModal}>
        <span className={style.iconVoltar}><AiOutlineArrowLeft size={24} onClick={()=>onClickNotificacoes()}/></span>
        <p className={style.searchModalTitle}>Notificações</p>
        {!notificacoesData || notificacoesData.length === 0 ? (
          <p className={style.noNotifications}>Nenhuma notificação</p>
        ) : (
          <>

            <div className={style.notificationItem}>
              {notificacoesData?.map(({ notificacao }) => (
                <span key={notificacao.id}>
                  {
                  notificacao.comentarioId? comentarioIdShow(notificacao):
                  notificacao.curtida_postagemId ? curtidapostagemShow(notificacao):
                  notificacao.curtida_comentarioId && curtidaComentarioShow(notificacao)
                  //notificacao.denuncia.statusDenuncia && denunciaShow(notificacao)
                  }
                </span>
              ))}
            </div>

            {!atingiuFim ? (
              <p className={style.showmore} onClick={() => setLoadMore(true)}>
                Mostrar mais
              </p>
            ):
            <p className={style.msgNoMore}>Sem mais notificações</p>
            }
          </>
        )}
      </div>
    );
}