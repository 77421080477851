import React from 'react';
import style from './style.module.css';
import { useNavigate } from 'react-router-dom';
function ShowUserLittleProfile({user,naoclicavel}) {
  const navigate = useNavigate();

  const handleClick = () =>{
    //navigate(`/${user.username}`);
    window.location.assign(`/${user.username}`);
  }
  return (
    <div className={style.showuserlittleprofile} onClick={naoclicavel==true?null:handleClick}>
      <img src={user.profile_image?user.profile_image:"https://firebasestorage.googleapis.com/v0/b/socialcity-702b9.appspot.com/o/universal%2Fprofile.jpeg?alt=media&token=cfcad71a-e3a6-43b9-bf0a-95c6e9c23f5c"} alt="Loading" />
      <div className={style.showuserlittleprofileData}>
        <p className={style.showuserlittleprofileName}>{user.nome} {user.sobrenome}</p>
        <p className={style.showuserlittleprofileArroba}>@{user.username}</p>
      </div>
    </div>
  );
}

export default ShowUserLittleProfile;
