import style from "./index.module.css";
import MenuEsquerdo from "../Home/MenuEsquerdo";
import { useState, useEffect, useRef } from "react";
import { obterQtdSegPosts } from "../global";
import { TabComponent } from "./TabComponent";
import { verificaSeSegue, handleSeguirOuNao,changeImageProfile,getUser } from "../global";
import LoadingScreen from "../LoadingScreen";
import ModalEditarPerfil from "./ModalEditarPerfil";
import ModalSeguidorPassivo from "./ModalSeguidor";

function ImageProfile({ imgDonoPerfil,userId,userIdDonoPerfil }) {
    const fileInputRef = useRef(null);
    const handleImageClick = () => {
        fileInputRef.current.click(); // Dispara o clique no input de arquivo
    };

    async function handleFileChange (event) {
        const file = event.target.files[0];
        if (file) {
          try{
            const result = await changeImageProfile(file);
            if(result){
                window.location.reload();
            }
          }catch(error){
            console.log(error);
          }
        }
    };

    return (
        <div className={style.borderContainer}>
            <img
                className={style.profileImage}
                src={imgDonoPerfil}
                onClick={userIdDonoPerfil==userId?handleImageClick:null}
                alt="Profile"
            />
            <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }} // Oculta o input
            onChange={handleFileChange} // Chama handleFileChange quando o arquivo é selecionado
            />
        </div>
    );
}

function ShowUserInfo({dataDonoPerfil, user, owner, seguidor, setSeguidor, atributos, setAtributos, erroMessage, loadingSeguirDesseguir, setLoadingSeguirDesseguir, janelaW}){
    const userId = user.uid;
    const [modalPassivohandler, setModalPassivoHandler] = useState(false); //Controla abertura modal de seguidores ou seguindo
    const [modalAtivoHandler, setModalAtivoHandler] = useState(false); //Controla abertura modal de seguidores ou seguindo

    const [modalEditarPerfilHandler, setModalEditarPerfilHandler] = useState(false); //Modal editar perfil
    async function handleSeguir(){
        if(loadingSeguirDesseguir || !userId) return; //Evita multiplas requisicoes
        try {
            const result = await handleSeguirOuNao(dataDonoPerfil.uid);
            if(result){
                setSeguidor(result.segue);
                setAtributos(prevState => ({
                    ...prevState,  // Preserva os valores anteriores
                    qtdSeguidores: result.seguidorCount  // Atualiza apenas qtdSeguidores
                }));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingSeguirDesseguir(false);
        }
    }


    return(
        <>
        <div className={style.lineOneContainer}>
                {modalEditarPerfilHandler&&owner&&<ModalEditarPerfil user={user} setModalEditarPerfilHandler={setModalEditarPerfilHandler}/>}
                {modalPassivohandler &&<ModalSeguidorPassivo owner={owner} user={dataDonoPerfil} setModalHandler={setModalPassivoHandler} tipo="passivo"/>}
                {modalAtivoHandler &&<ModalSeguidorPassivo owner={owner} user={dataDonoPerfil} setModalHandler={setModalAtivoHandler} tipo="ativo"/>}

                {<ImageProfile imgDonoPerfil={dataDonoPerfil.profile_image} userIdDonoPerfil={dataDonoPerfil.uid} userId={userId}/>} 
                <div className={style.rightSideFromImg}> {/*Flex column*/}
                    <div className={style.organizador}>
                        <div className={style.leftSide}>
                            <p className={style.nomeSobrenome}>{dataDonoPerfil.nome} {dataDonoPerfil.sobrenome}</p>
                            <p className={style.userNamePerfil}>@{dataDonoPerfil.username}</p>
                        </div>
                        {!owner&&
                            <button className={style.settings} onClick={handleSeguir} disabled={loadingSeguirDesseguir}>
                                {seguidor?"Desfazer":"Seguir"}
                            </button>
                        }
                        {owner &&<button onClick={()=>setModalEditarPerfilHandler(true)} className={style.settings}>Editar</button>}
                    </div>
                    {   
                        janelaW>800&&
                        <div className={style.stats}> {/*Nada*/}
                            <p>{atributos.qtdPosts} postagens</p>
                            <p className={style.cursorPointer} onClick={()=>setModalPassivoHandler(true)}>{atributos.qtdSeguidores} seguidores</p>
                            <p className={style.cursorPointer} onClick={()=>setModalAtivoHandler(true)}>{atributos.qtdSeguindo} seguindo</p>
                        </div>
                    }

                    <p>{erroMessage}</p>
                </div>
        </div>
        {
        janelaW<800&&
        <span>
            <hr className={style.statsMobileHr}/>
            <div className={style.statsMobile}> {/*Nada*/}
                <span className={style.statsMobileSpan}>
                    <p>{atributos.qtdPosts}</p>
                    <p>postagens</p>
                </span>
                <span className={style.statsMobileSpan} onClick={()=>setModalPassivoHandler(true)}>
                    <p>{atributos.qtdSeguidores}</p>
                    <p>seguidores</p>
                </span>
                <span className={style.statsMobileSpan} onClick={()=>setModalAtivoHandler(true)}>
                    <p>{atributos.qtdSeguindo}</p>
                    <p>seguindo</p>
                </span>
            </div>
            <hr className={style.statsMobileHr}/>
        </span>
        }
        </>
        
    )
}

export default function Index({user, setUser}){
    const [dataDonoPerfil,setDataDonoperfil] = useState(); //Dados do usuario q foi pego na url.
    const [loading,setLoading] = useState(true); //Controla gif loading postagens e impede multiplas requisicoes.
    const [atingiuFimPostagens, setAtingiuFimPostagens] = useState(false);//Vai ser true APENAS se não tiver mais postagens para fazer load.
    const [atingiuFimShorts, setAtingiuFimShorts] = useState(false);//Vai ser true APENAS se não tiver mais Shorts para fazer load.
    const [loadMore, setLoadMore] = useState(false); // Serve pra ativar useEffects pra fazer load de mais posts.
    const [owner, setOwner] = useState(); //Define se usuário é dono ou não do perfil
    const [exibirHtml, setExibirHtml] = useState(false); //Controla quando deve exibir o html, ou seja, qd carregar tudo.
    const userId = user.uid; //Id do usuário logado
    const postagensRef = useRef(null); //Escuta Div, usado pra detectar se chegou ao fim da div pra load de mais posts
    const [seguidor, setSeguidor] = useState(); //Controla se usuário logado segue ou nao dono do perfil: boolean
    const [erroMessage,setErroMessage] = useState(); //<p></p> tem essa variavel dentro pra exibir erro
    const [loadingSeguirDesseguir, setLoadingSeguirDesseguir] = useState(true); //Evita multiplas requisicoes
    const [activeTab, setActiveTab] = useState('posts'); // Estado para a aba ativa, usado tb como parâmetro pra pegar shorts ou posts
    const [janelaW, setjanelaW] = useState(window.innerWidth);

    window.addEventListener('resize', () => { /*Listener de resize da janela pra mudar menu pro mobile */
        let larguraJanela = window.innerWidth;
        setjanelaW(larguraJanela);
    });


    const [atributos, setAtributos] = useState({
        qtdSeguidores:0,
        qtdSeguindo:0,
        qtdPosts:0
    })
 

    useEffect(()=>{ //Pega dataDonoPerfil e Owner
        /*
        //Pegar dados do Dono do perfil via url 
        Este código: 
            1 - Pega dados do dono do perfil (dataDonoperfil) pela url e busca no sql, guardando uid, nome etc;
            2 - Pega (owner), q é um boolean que diz se usuário logado é dono do perfil (true) ou não (false);
            3 - Caso owner seja falso (usuario logado n é dono do perfil), pega também se usuário logado segue dono do perfil ou não;
        */
        async function fetchData(){ //Fetch data from url
            const url = window.location.pathname; // Obtém o caminho da URL
            const parametro = url.substring(url.lastIndexOf('/') + 1); // Pega a parte após a última barra
            const usernameUrl = decodeURIComponent(parametro); //UserName do perfil
            try{
                //Pegar dados do dono do perfil, msm se usuario logado for o dono
                var donoPerfil;
                donoPerfil = await getUser("terceiro",usernameUrl); 
                if(donoPerfil){
                    setDataDonoperfil(donoPerfil);
                    if(userId==donoPerfil.uid){ //Usuario corrente é o dono do perfil
                        setOwner(true);
                    }else{
                        setOwner(false);
                    }
                }else{
                    setDataDonoperfil(null);
                    setExibirHtml(true);
                }
                
                setLoadingSeguirDesseguir(false);
            }catch(todosErros){
                gerarMsgErro(1000);
            }
        }
        fetchData(); 
    },[])

    useEffect(() => { //Escuta scroll e, se ta no final do scroll, seta loadMore=true, que porventura pega dados no TabComponente
        /*
        //Escuta o scroll pra load de posts
        Este código:
            1 - Escuta o scroll e verifica se está quase no fim, para poder fazer load de mais posts;
            2 - A variável "atingiuFimPostagens" é true quando não tem mais postagens para fazer load, ou seja, acabou postagens.
         */
      const handleScroll = () => {
          if (postagensRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = postagensRef.current;
            if(activeTab=="posts" && !atingiuFimPostagens){
                if (scrollTop + clientHeight >= scrollHeight - 100 && loading) { 
                    setLoadMore(true); //Ativa listener pra pegar mais posts
                    setLoading(false); //Impede de chamar dnv
                    setTimeout(() => { //Dps de 3 secs ativa a possibilidade de load dnv
                      setLoading(true);
                    }, 1000);
                  } 
            }else if(activeTab=="shorts" &&!atingiuFimShorts){
                if (scrollTop + clientHeight >= scrollHeight - 100 && loading) { 
                    setLoadMore(true); //Ativa listener pra pegar mais posts
                    setLoading(false); //Impede de chamar dnv
                    setTimeout(() => { //Dps de 3 secs ativa a possibilidade de load dnv
                      setLoading(true);
                    }, 1000);
                  } 
            }
            
            
          }
      };
      const div = postagensRef.current;
      div?.addEventListener('scroll', handleScroll);
      return () => div?.removeEventListener('scroll', handleScroll);
    },[loading, loadMore]); 

    useEffect(()=>{ //Obtem seguidores e se usuario logado segue dono perfil 
        async function obterSeguidores(){ 
            const result = await obterQtdSegPosts(dataDonoPerfil.uid); //Pega imagem de perfil, seguidores, seguindo, qtdposts do dono perfil
            if(result!=null && result!=undefined){
             setAtributos({
                qtdSeguidores:result.seguidorCount,
                qtdSeguindo:result.seguindoCount,
                qtdPosts:result.postCount
             })
            }
        }
        async function verificaSeUserLogadoSegueDonoperfil(){
            //Verificar se usuario logado segue dono perfil
            const result = await verificaSeSegue(user.uid,dataDonoPerfil.uid); //Retorna boolean
            if(result.segue){
                setSeguidor(true);
            }
            else {
                setSeguidor(false);
            }
            setExibirHtml(true);
        }
        if(dataDonoPerfil){obterSeguidores()};
        if(dataDonoPerfil){verificaSeUserLogadoSegueDonoperfil()};
    },[dataDonoPerfil]);
    
    function gerarMsgErro(codigo){
        //Cada erro NESSA PÁGINA tem um código diferente e único, para caso ocorra, os clientes possam questionar o "erro X".
        setErroMessage("Estamos com instabilidade em alguns recursos no perfil. Já estamos cientes e trabalhando nisso. Instabilidade código ",codigo);
    }
    return(<>
        {exibirHtml?
            <span>
                <div className={style.container} ref={postagensRef}>
                    <div className={style.menu_lateral}><MenuEsquerdo user={user} setUser={setUser}/></div>
                    <div className={style.espaco_central}>
                        <div className={style.espacoUsado}>
                            {dataDonoPerfil && dataDonoPerfil!=null?
                            (
                                <span>
                                    <div className={style.userInfoSpan}>
                                        <ShowUserInfo dataDonoPerfil={dataDonoPerfil} user={user} owner={owner} seguidor={seguidor} setSeguidor={setSeguidor} atributos={atributos} setAtributos={setAtributos} erroMessage={erroMessage} loadingSeguirDesseguir={loadingSeguirDesseguir} setLoadingSeguirDesseguir={setLoadingSeguirDesseguir} janelaW={janelaW}/>
                                    </div>
                                    <span>
                                        <TabComponent atingiuFimPostagens={atingiuFimPostagens} loading={loading} dataDonoPerfil={dataDonoPerfil} setAtingiuFimPostagens={setAtingiuFimPostagens} setLoadMore={setLoadMore} user={user} loadMore={loadMore} setAtingiuFimShorts={setAtingiuFimShorts} atingiuFimShorts={atingiuFimShorts} activeTab={activeTab} setActiveTab={setActiveTab}/>
                                    </span>
                                </span>
                            )
                            :
                            (
                                <div className={style.perfilInexistente}>
                                    <p>Perfil não existe ou usuário deletou o perfil.</p>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </span>
            :
            <LoadingScreen/>
        }
        </>)
}