import style from "./style.module.css";
import { FaPaperPlane } from 'react-icons/fa';
import { useState, useRef, useEffect } from "react";
import loading from "../../Imgs/LoadSnail.gif";
import React from "react";
import { obterToken } from "../../global";
import apiUrl from "../../global";

import axios from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import { FaPlay, FaPause } from 'react-icons/fa';

function ExibirVideoSelecionado({ videoSrc }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const videoRef = useRef(null);
  
    const handlePlayPause = () => {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
        setShowButton(false); // Esconde o botão após clicar
      }
    };
  
    useEffect(() => {
      if (isPlaying) {
        // Mostra o botão quando o vídeo é reproduzido
        setShowButton(true);
        const hideButtonTimeout = setTimeout(() => {
          setShowButton(false);
        }, 1000); // Esconde o botão após 1 segundos
  
        return () => clearTimeout(hideButtonTimeout);
      } else {
        // Sempre mostra o botão quando o vídeo está pausado
        setShowButton(true);
      }
    }, [isPlaying]);
  
    return (
      <div className={style.videoContainer}>
        <video 
          ref={videoRef}
          onClick={handlePlayPause}
          src={videoSrc} 
          controls={false} // Remove os controles padrão
          style={{ maxWidth: '100%', maxHeight: '100%' }} // Estilo opcional para ajustar o tamanho
        />
        {showButton && (
          <div className={style.playPauseButton} onClick={handlePlayPause}>
            {isPlaying ? <FaPause size={50} /> : <FaPlay size={50} />}
          </div>
        )}
      </div>
    );
  }

export default function Index({user, onClickFadeBackground}){
    const [isLoadingModal, setIsLoadingModal] = useState(false); //true? está enviando shorts, telinha de carregando
    const [error, setError] = useState("");
    const [descricaoPostagem, setDescricaoPostagem] = useState(""); //guarda texto da postagem
    const [videoSrc, setVideoSrc] = useState(""); //Para exibir o vídeo

    const meufile = useRef();

    const clearFileInput = () => {
        if (meufile.current) {
            meufile.current.value = ''; // Limpa o campo de arquivo
        }
        setVideoSrc(false);
    };

    function handlePostError(error) {
        if (error.response && error.response.data) {
          const { code, message } = error.response.data;
          setError(message || 'Ocorreu um erro inesperado.');
        } else {
          setError('Ocorreu um erro inesperado.');
        }
      }

      
    async function enviarShorts(){
        if(!videoSrc){setError("Selecione um vídeo para continuar"); return;}
        const formData = new FormData();
        formData.append('descricao', descricaoPostagem);
        formData.append('video', meufile.current.files[0]);
        try {
            setIsLoadingModal(true);
            // Obtém o token de autenticação
            const token = await obterToken();
    
            // Faz a requisição POST para a API
            
            const response = await axios.post(`${apiUrl}/criarShorts`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            setVideoSrc(''); // Limpa o vídeo exibido
            setDescricaoPostagem(''); // Limpa a descrição
            window.location.reload();
        } catch (erro) {
            handlePostError(erro);
            setVideoSrc(''); // Limpa o vídeo exibido
            setDescricaoPostagem(''); // Limpa a descrição
        }finally{
            setIsLoadingModal(false);
        }


    }
    function getRatioString(ratio) {
        // Função auxiliar para obter a string da proporção
        const ratios = {
            [9/16]: '9:16',
            [2/3]: '2:3',
            [1/1]: '1:1',
            [3/4]: '3:4',
            [4/5]: '4:5',
            /*[16/9]: '16:9', desktop
            [3/2]: '3:2'*/
        };

        return ratios[ratio] || 'Desconhecida';
    }
    function verificaDimensao(file, allowedAspectRatios = [9/16, 2/3, 4/5, 1/1, 3/4], tolerance = 0.05) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(file);
    
            video.onloadedmetadata = () => {
                const width = video.videoWidth;
                const height = video.videoHeight;
                const aspectRatio = width / height;
    
                // Verifica se o vídeo carregou corretamente
                if (width === 0 || height === 0) {
                    reject(new Error('Erro ao carregar o vídeo'));
                    return;
                }
    
                // Encontra a proporção mais próxima da lista de proporções permitidas
                const ratioString = allowedAspectRatios.map(ratio => {
                    const minRatio = ratio - tolerance;
                    const maxRatio = ratio + tolerance;
                    return {
                        ratio,
                        ratioString: getRatioString(ratio)
                    };
                }).find(({ ratio, ratioString }) => aspectRatio >= ratio - tolerance && aspectRatio <= ratio + tolerance)?.ratioString || 'Desconhecida';
    
                // Define se o vídeo é válido ou não com base na proporção
                const isValid = ratioString !== 'Desconhecida';
    
                resolve({ isValid, ratio: ratioString });
    
                // Libere o objeto URL após a verificação
                URL.revokeObjectURL(video.src);
            };
    
            video.onerror = (error) => {
                reject(error);
            };
        });
    }
    async function handleFileChange(event) {
        const file = event.target.files[0];
        const maxSizeInMB = 50; // Tamanho máximo em MB
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

        setError("");

        if (!file) { //Se n tem video (caso o usuario clique pra adc video e feche)
            return; //Então retorna e n faça nada.
        }

        if (!file.type.startsWith('video/')) { // Verifica se o arquivo é um vídeo
            //alert('O arquivo selecionado não é um vídeo.');
            setError('O arquivo selecionado não é um vídeo.');
            clearFileInput();
            return;
        }
    
        if (file.size > maxSizeInBytes) {
            //alert(`O arquivo de vídeo é muito grande. O tamanho máximo permitido é de ${maxSizeInMB} MB.`);
            setError(`Arquivo muito pesado. O tamanho máximo permitido é ${maxSizeInMB} MB.`);
            clearFileInput();
            return;
        }

        const result = await verificaDimensao(file);
        if(result.isValid==false){
            setError(`Vídeo possúi proporções inadequadas, favor escolher outro.`);
            clearFileInput();
            return; 
        }

         // Cria um URL temporário para o vídeo e atualiza o estado
         const videoUrl = URL.createObjectURL(file);

        // Cria um elemento de vídeo para verificar a duração
        const video = document.createElement('video');
        video.src = videoUrl;
        
        //Verifica duração
        video.addEventListener('loadedmetadata', () => {
            const duration = video.duration;
            const minDuration = 5; // Duração mínima em segundos
            const maxDuration = 120; // Duração máxima em segundos
    
            if (duration < minDuration || duration > maxDuration) {
                setError(`A duração do vídeo deve estar entre ${minDuration} e ${maxDuration} segundos.`);
                clearFileInput();
                //alert(`A duração do vídeo deve estar entre ${minDuration} e ${maxDuration} segundos.`);
                URL.revokeObjectURL(videoUrl); // Libera o URL temporário
                return;
            }
    
            // Se a duração estiver dentro dos limites, atualiza o estado
            setVideoSrc(videoUrl);
        });


     
    }
    return(
        <div className={style.modalShorts}>
            <div className={style.modalHeader}>
                <FaArrowLeft size={24} className={style.iconVoltar} onClick={()=>onClickFadeBackground()}/>
                <p>Criar novo Shorts</p>
                <span onClick={isLoadingModal ? null : enviarShorts} className={style.iconSend}><FaPaperPlane /></span>
            </div>
            <hr/>
            <div className={style.modalPostagemDiv}>
            {isLoadingModal ? 
                (
                <div className={style.loadingImg}>
                    <p>Você pode continuar usando a plataforma enquanto é feito upload de sua postagem.</p>
                    <img src={loading} alt="Loading" />
                </div>
                ) 
                : 
                (
                <React.Fragment>
                    <textarea className={style.textAreaDescricao} name="descricao" placeholder='Máximo 2000 caracteres' maxLength="2000" value={descricaoPostagem} onChange={e => setDescricaoPostagem(e.target.value)}></textarea>
                    <p className={style.error}>{error}</p>
                    {!error&&<p>Entre 5 e 120 segundos</p>}
                    <button onClick={() => meufile.current.click()} className={style.buttonSelectMidia}>Selecionar Shorts</button>
                    {videoSrc && <ExibirVideoSelecionado videoSrc={videoSrc}/>}
                    <input type="file" ref={meufile} style={{ display: 'none' }} accept="video/*" onChange={handleFileChange}/>                    <div className={style.selectedMidia}>
                    </div>
                </React.Fragment>
            )}
            </div>
        </div>
    )
}